const useSearchParams = (searchParams, setSearchParams) => {
  const queryParams = (order, pagination) => {
    const filterParams = {};
    searchParams.forEach(param => {
      filterParams[param.fieldName] = param.fieldValue;
    });
    if (order) {
      // eslint-disable-next-line no-restricted-syntax
      for (const orderKey of Object.keys(order)) {
        filterParams[orderKey] = order[orderKey];
      }
    }
    if (pagination) {
      filterParams.limit = pagination.pageSize;
      filterParams.page = pagination.currentPage;
    }
    return filterParams;
  };

  const handleColumnSearch = (searchValue, confirm, columnName) => {
    const paramIndex = searchParams.findIndex(x => x.fieldName === columnName);

    if (paramIndex >= 0) {
      // eslint-disable-next-line no-unused-expressions
      searchValue
        ? searchParams.splice(paramIndex, 1, { fieldName: columnName, fieldValue: searchValue })
        : searchParams.splice(paramIndex, 1);

      setSearchParams([...searchParams]);
    } else {
      setSearchParams([...searchParams, { fieldName: columnName, fieldValue: searchValue }]);
    }

    confirm();
  };

  const handleResetColumnSearch = (columnName, clearFilters) => {
    clearFilters();
  };

  const orderParams = sorter => {
    const orderParam = sorter;
    return {
      ...(orderParam.column && {
        order: `${orderParam.columnKey}:${orderParam.order === 'ascend' ? 'ASC' : 'DESC'}`,
      }),
    };
  };
  return [queryParams, handleColumnSearch, handleResetColumnSearch, orderParams];
};

export default useSearchParams;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/locale/es';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import client from '../../../api/api';
import CarrierRecordBillingTable from './CarrierRecordBillingTable';
import SettlementAdvanceEnabled from './SettlementAdvanceEnabled';
import './carrier-record-billing.css';
import CarrierRecordBillingTable2 from './CarrierRecordBillingTable/CarrierRecordBillingTable2';

const SettlementWrap = styled.div`
  p.errorMsg {
    color: white;
    background: red;
    padding: 10px;
    text-align: center;
  }
`;

const IconWrap = styled.div`
  button {
    color: #6495ed;
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
  }

  button:focus {
    outline: none;
  }
`;

const CarrierRecordBillingView = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [settlement, setSettlement] = useState('');
  const [showSettlementPopUp, setShowSettlementPopUp] = useState(false);
  const [showInvoices, setShowInvoices] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [debitNoteList, setDebitNoteList] = useState([]);
  const [showDebitNotes, setShowDebitNotes] = useState(false);
  const [creditNoteList, setCreditNoteList] = useState([]);
  const [showCreditNotes, setShowCreditNotes] = useState(false);
  const [gasoilInvoicesList, setGasoilInvoicesList] = useState([]);
  const [showGasoilInvoices, setShowGasoilInvoices] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [advanceEnabled, setAdvanceEnabled] = useState(false);

  const fetchSettlementFromBucket = async settlementFileName => {
    try {
      const res = await client.endpoints.carrier.getSettlementFile(settlementFileName);
      const reader = new FileReader();
      reader.readAsDataURL(res.data);
      reader.onloadend = () => {
        const base64data = reader.result;
        setSettlement('');
        setSettlement(p => p.concat([base64data]));
        setShowSettlementPopUp(true);
      };
    } catch (error) {
      // console.error(error);
    }
  };

  const fetchInvoiceFromBucket = async invoiceFileNameList => {
    try {
      invoiceFileNameList.forEach(async billingFileName => {
        const res = await client.endpoints.carrier.getInvoiceFiles(billingFileName);
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          const base64data = reader.result;
          setInvoiceList(p => p.concat([{ pdf: base64data }]));
          setShowInvoices(true);
        };
      });
    } catch (error) {
      // console.error(error);
    }
  };

  const fetchDebitNotesFromBucket = async debitNoteFileNameList => {
    try {
      debitNoteFileNameList.forEach(async debitNoteFileName => {
        const res = await client.endpoints.carrier.getDebitNotesFiles(debitNoteFileName);
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          const base64data = reader.result;
          setDebitNoteList(p => p.concat([{ pdf: base64data }]));
          setShowDebitNotes(true);
        };
      });
    } catch (error) {
      // console.error(error);
    }
  };

  const fetchCreditNotesFromBucket = async creditNoteFileNameList => {
    try {
      creditNoteFileNameList.forEach(async creditNoteFileName => {
        const res = await client.endpoints.carrier.getDebitNotesFiles(creditNoteFileName);
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          const base64data = reader.result;
          setCreditNoteList(p => p.concat([{ pdf: base64data }]));
          setShowCreditNotes(true);
        };
      });
    } catch (error) {
      // console.error(error);
    }
  };

  const fetchGasoilInvoicesFromBucket = async gasoilInvoicesFileNameList => {
    try {
      gasoilInvoicesFileNameList.forEach(async gasoilInvoicesFileName => {
        const res = await client.endpoints.carrier.getInvoiceFiles(gasoilInvoicesFileName);
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          const base64data = reader.result;
          setGasoilInvoicesList(p => p.concat([{ pdf: base64data }]));
          setShowGasoilInvoices(true);
        };
      });
    } catch (error) {
      // console.error(error);
    }
  };

  const fetchAction = async filters => {
    const res = await client.endpoints.carrier.getInvoices(filters);
    if (res.status === 200) {
      const sortedInvoices = res.data.docs.sort((a, b) =>
        moment(b.invoiceExpirationDate).diff(moment(a.invoiceExpirationDate))
      );
      setIsLoading(false);
      setData(sortedInvoices);
      setPageCount(Math.ceil(res.data.count / filters.limit));
    }
  };

  const fetchShipments = useCallback(params => {
    // TODO - cambier filters por where o algo por el estilo
    const { filters = {}, sortBy = {}, pageIndex = 0, pageSize = 10 } = params || {};
    const fetch = async () => {
      setIsLoading(true);
      let fetchFilters = {
        ...filters,
        ...{ limit: pageSize, page: pageIndex + 1 },
      };
      if (sortBy && sortBy['0']) {
        const sorting = `${sortBy[0].id}:${sortBy[0].desc ? 'desc' : 'asc'},`;
        fetchFilters = { ...fetchFilters, ...{ sorting } };
      }
      if (!sortBy['0']) {
        fetchFilters = { ...fetchFilters, ...{ sorting: 'invoiceExpirationDate:desc' } };
        fetchAction(fetchFilters);
      } else {
        await fetchAction(fetchFilters);
      }
    };
    fetch();
  }, []);

  const checkEnabled = async () => {
    try {
      const res = await client.endpoints.carrier.isSettlementAdvanceEnabled();
      setAdvanceEnabled(res.data.enabled);
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  useEffect(() => {
    checkEnabled();
  }, []);

  const renderRowSubComponent = useCallback(({ row }) => {
    const { original } = row;
    const { settlements } = original;
    return settlements ? (
      <>
        <div className="settlement-shipment-header">
          <p className="fit-md-content">Fecha Viaje</p>
          <p className="fit-md-content">Carta de Porte</p>
          <p className="fit-md-content">Producto</p>
          <p className="fit-md-content">Desde</p>
          <p className="fit-md-content">Hasta</p>
          <p className="fit-lg-content">Tarifa</p>
          <p className="fit-lg-content">Chofer</p>
          <p className="fit-md-content">CUIT Chofer</p>
          <p className="fit-sm-content">Tractor</p>
          <p className="fit-sm-content">Remolque</p>
          <span className="amounts">
            <p className="fit-sm-content">Bruto</p>
            <p className="fit-sm-content">Diferencia</p>
            <p className="fit-sm-content">Ajuste</p>
            <p className="fit-sm-content">Neto</p>
            <p className="fit-sm-content">IVA</p>
            <p className="fit-sm-content">Gasoil</p>
            <p className="fit-sm-content">Final</p>
          </span>
        </div>
        {settlements.map(s => {
          return (
            <div className="settlement-shipment">
              <p className="fit-md-content">{moment(s.settlementDate).format('DD-MM-YYYY')}</p>
              <p className="fit-md-content">{s.reportNumber}</p>
              <p className="fit-md-content">{s.productType}</p>
              <p className="fit-md-content">{s.fromCity}</p>
              <p className="fit-md-content">{s.toCity}</p>
              <p className="fit-lg-content">
                $ {s.fare}/{s.fareType} +IVA
              </p>
              <p className="fit-lg-content">{s.truckerName}</p>
              <p className="fit-md-content">{s.truckerCuit}</p>
              <p className="fit-sm-content">{s.truckPatent}</p>
              <p className="fit-sm-content">{s.trailerPatent}</p>
              <span className="amounts">
                <p className="fit-sm-content">$ {s.grossTax.toFixed(2)}</p>
                <p className="fit-sm-content">$ {s.productDifferenceAmount.toFixed(2)}</p>
                <p className="fit-sm-content">$ {s.adjustmentAmount.toFixed(2)}</p>
                <p className="fit-sm-content">$ {s.netAmount.toFixed(2)}</p>
                <p className="fit-sm-content">$ {s.vatTax.toFixed(2)}</p>
                <p className="fit-sm-content">$ {s.gasoilAmount.toFixed(2)}</p>
                <p className="fit-sm-content">$ {s.totalAmount.toFixed(2)}</p>
              </span>
            </div>
          );
        })}
      </>
    ) : (
      ''
    );
  }, []);

  const columns = useMemo(() => [
    {
      id: 'expander',
      Cell: ({ row }) => {
        const { original } = row;
        const { settlements } = original;
        return (
          <span {...row.getToggleRowExpandedProps()}>
            {settlements.length ? (
              <>
                {row.isExpanded ? (
                  <div className="opened">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                ) : (
                  <div className="open">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                )}
              </>
            ) : (
              <div className="no-shipments">
                <p>Sin viajes</p>
              </div>
            )}
          </span>
        );
      },
    },
    {
      id: 'documents',
      Cell: ({ row }) => {
        const { original } = row;
        const { documents } = original;
        return (
          <IconWrap>
            <button
              type="button"
              onClick={() => {
                setInvoiceList([]);
                setDebitNoteList([]);
                setCreditNoteList([]);
                setGasoilInvoicesList([]);
                setShowInvoices(false);
                setShowDebitNotes(false);
                setShowCreditNotes(false);
                setShowGasoilInvoices(false);
                fetchSettlementFromBucket(documents.settlement);
                fetchInvoiceFromBucket(documents.invoices);
                fetchDebitNotesFromBucket(documents.debitNotes);
                fetchCreditNotesFromBucket(documents.creditNotes);
                fetchGasoilInvoicesFromBucket(documents.gasoil);
              }}
            >
              <img src="../../../images/icons/document.png" alt="document-icon" />
            </button>
          </IconWrap>
        );
      },
    },
    {
      id: 'adelanto',
      Cell: ({ row }) => {
        const { original } = row;
        const { canAskAdvance } = original;
        return (
          <>
            {advanceEnabled && canAskAdvance ? (
              <SettlementAdvanceEnabled billingId={original._id} setErrorMsg={setErrorMsg} />
            ) : (
              <img src="../../../images/icons/loading.png" alt="loading" />
            )}
          </>
        );
      },
    },
    {
      Header: 'Razón social',
      accessor: 'businessName',
      id: 'businessName',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'CUIT',
      accessor: 'cuit',
      id: 'cuit',
      disableFilters: true,
      disableSorting: true,
      width: 50,
    },
    {
      Header: 'Tipo',
      accessor: 'invoiceType',
      id: 'invoiceType',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Nro',
      accessor: 'invoiceNumber',
      id: 'invoiceNumber',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Emisión',
      accessor: ({ billingDate }) => {
        return billingDate
          ? moment(billingDate)
              .locale('es')
              .format('DD-MMM-YYYY')
          : '';
      },
      id: 'billingDate',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Vencimiento',
      accessor: ({ invoiceExpirationDate }) => {
        return invoiceExpirationDate
          ? moment(invoiceExpirationDate)
              .locale('es')
              .format('DD-MMM-YYYY')
          : '';
      },
      id: 'invoiceExpirationDate',
      disableFilters: true,
    },
    {
      Header: 'Pago',
      accessor: ({ paymentDate }) => {
        return paymentDate
          ? moment(paymentDate)
              .locale('es')
              .format('DD-MMM-YYYY')
          : '';
      },
      id: 'paymentDate',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: <span style={{ float: 'right', marginRight: '18px' }}>Bruto</span>,
      id: 'grossTax',
      Cell: ({ row }) => {
        const { original } = row;
        const { grossTax } = original;
        return (
          <span style={{ float: 'right' }}>{`$ ${new Intl.NumberFormat('es', {
            style: 'decimal',
            currency: 'ARS',
            minimumFractionDigits: 2,
          }).format(grossTax)}`}</span>
        );
      },
    },
    {
      Header: <span style={{ float: 'right', marginRight: '18px' }}>Neto</span>,
      Cell: ({ row }) => {
        const { original } = row;
        const { netAmount } = original;
        return (
          <span style={{ float: 'right' }}>{`$ ${new Intl.NumberFormat('es', {
            style: 'decimal',
            currency: 'ARS',
            minimumFractionDigits: 2,
          }).format(netAmount)}`}</span>
        );
      },
      id: 'netAmount',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: <span style={{ float: 'right', marginRight: '18px' }}>IVA</span>,
      Cell: ({ row }) => {
        const { original } = row;
        const { vatTax } = original;
        return (
          <span style={{ float: 'right' }}>{`$ ${new Intl.NumberFormat('es', {
            style: 'decimal',
            currency: 'ARS',
            minimumFractionDigits: 2,
          }).format(vatTax)}`}</span>
        );
      },
      id: 'vatTax',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: <span style={{ float: 'right', marginRight: '18px' }}>Gasoil</span>,
      Cell: ({ row }) => {
        const { original } = row;
        const { gasoilAdvance } = original;
        return (
          <span style={{ float: 'right' }}>
            {`$ ${new Intl.NumberFormat('es', {
              style: 'decimal',
              currency: 'ARS',
              minimumFractionDigits: 2,
            }).format(gasoilAdvance)}`}
          </span>
        );
      },
      id: 'gasoilAdvance',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: <span style={{ float: 'right', marginRight: '18px' }}>Total Producto</span>,
      Cell: ({ row }) => {
        const {
          original: { productDifferenceAmountTotal },
        } = row;
        return (
          <span style={{ float: 'right' }}>{`$ ${new Intl.NumberFormat('es', {
            style: 'decimal',
            currency: 'ARS',
            minimumFractionDigits: 2,
          }).format(productDifferenceAmountTotal)}`}</span>
        );
      },
      id: 'productDifferenceAmountTotal',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: <span style={{ float: 'right', marginRight: '18px' }}>Total Comisión</span>,
      Cell: ({ row }) => {
        const {
          original: { comissionAmountTotal },
        } = row;
        return (
          <span style={{ float: 'right' }}>
            {`$ ${new Intl.NumberFormat('es', {
              style: 'decimal',
              currency: 'ARS',
              minimumFractionDigits: 2,
            }).format(comissionAmountTotal)}`}
          </span>
        );
      },
      id: 'comissionAmountTotal',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: <span style={{ float: 'right', marginRight: '18px' }}>Total Ajuste</span>,
      Cell: ({ row }) => {
        const {
          original: { adjustmentAmountTotal },
        } = row;
        return (
          <span style={{ float: 'right' }}>{`$ ${new Intl.NumberFormat('es', {
            style: 'decimal',
            currency: 'ARS',
            minimumFractionDigits: 2,
          }).format(adjustmentAmountTotal)}`}</span>
        );
      },
      id: 'adjustmentAmountTotal',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: <span style={{ float: 'right', marginRight: '18px' }}>Final</span>,
      Cell: ({ row }) => {
        const { original } = row;
        const { totalAmount } = original;
        return (
          <span style={{ float: 'right' }}>{`$ ${new Intl.NumberFormat('es', {
            style: 'decimal',
            currency: 'ARS',
            minimumFractionDigits: 2,
          }).format(totalAmount)}`}</span>
        );
      },
      id: 'totalAmount',
      disableFilters: true,
      disableSorting: true,
    },
  ]);

  return (
    <SettlementWrap>
      {errorMsg ? <p className="errorMsg">{errorMsg}</p> : ''}
      <div className="carrier-record-billing-view-wrapper">
        <CarrierRecordBillingTable2 advanceEnabled={advanceEnabled} />
      </div>
    </SettlementWrap>
  );
};

export default CarrierRecordBillingView;

import React from 'react';
import { PropTypes, object } from 'prop-types';
import styled from 'styled-components';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const SideInfoWrap = styled.div`
  background: black;
  width: 100%;
  font-size: 17px;
  color: white;
  text-align: left;
  padding: 10px;
  margin: 26px 0;
  display: flex;
  flex-direction: column;

  h2 {
    margin: 4px 10px;
  }

  h4 {
    color: #ffbb0f;
  }

  p {
    color: whitesmoke;
    font-size: 14px;
  }

  label {
    color: gray;
    width: 100%;
    font-size: 12px;
    margin: 0;
  }

  .trucker-data,
  .billing-data,
  .truck-data,
  .trailer-data {
    padding: 3px 20px;
  }

  .trailer-data {
    margin-bottom: 70px;
  }

  ${media.desktop} {
    width: 27pc;
    border-radius: 20px;
    margin: 23px;
  }
`;

const SideInfo = ({ trucker }) => {
  const {
    truckerCuit,
    truckerName,
    cellNumber,
    billingCuit,
    cbu,
    legalName,
    truckPatent,
    trailerPatent,
    trailerBrand,
    trailerModel,
    trailerBoxType,
    trailerModelYear,
    trailerAxles,
    bodyTypeSelected,
  } = trucker;

  return (
    <SideInfoWrap>
      <h2>Tu camionero</h2>
      <div className="trucker-data">
        <h4>{truckerCuit ? 'Datos del chofer' : ''}</h4>
        {truckerCuit ? (
          <label htmlFor="trucker-name">
            CUIT
            <p id="trucker-name">{truckerCuit}</p>
          </label>
        ) : (
          ''
        )}

        {cellNumber ? (
          <label htmlFor="cellNumber">
            Celular
            <p id="trucker-name">{cellNumber}</p>
          </label>
        ) : (
          ''
        )}
        {truckerName ? (
          <label htmlFor="trucker-name">
            Nombre de Chofer
            <p id="trucker-name">{truckerName}</p>
          </label>
        ) : (
          ''
        )}
      </div>
      <div className="billing-data">
        <h4>{billingCuit ? 'Datos de facturación' : ''}</h4>
        {billingCuit ? (
          <label htmlFor="billing-cuit">
            CUIT de facturación
            <p id="billing-cuit">{billingCuit}</p>
          </label>
        ) : (
          ''
        )}

        {cbu ? (
          <label htmlFor="cbu">
            CBU
            <p id="cbu">{cbu}</p>
          </label>
        ) : (
          ''
        )}
        {legalName ? (
          <label htmlFor="legal-name">
            Razón social
            <p id="legal-name">{legalName}</p>
          </label>
        ) : (
          ''
        )}
      </div>
      <div className="truck-data">
        <h4>{truckPatent ? 'Datos del tractor' : ''}</h4>
        {truckPatent ? (
          <label htmlFor="truck-patent">
            Patente
            <p id="truck-patent">{truckPatent}</p>
          </label>
        ) : (
          ''
        )}
      </div>
      <div className="trailer-data">
        <h4>{trailerPatent ? 'Datos del remolque' : ''}</h4>
        {trailerPatent ? (
          <label htmlFor="truck-patent">
            Patente
            <p id="truck-patent">{trailerPatent}</p>
            Marca
            <p>{trailerBrand}</p>
            Modelo
            <p>{trailerModel}</p>
            Tipo de Caja
            <p>{trailerBoxType}</p>
            Año
            <p>{trailerModelYear}</p>
            Cantidad de Ejes
            <p>{trailerAxles}</p>
          </label>
        ) : (
          ''
        )}
        {bodyTypeSelected.label ? (
          <label htmlFor="body-type">
            Carrocería
            <p id="body-type">{bodyTypeSelected.label}</p>
          </label>
        ) : (
          ''
        )}
      </div>
    </SideInfoWrap>
  );
};

SideInfo.propTypes = {
  trucker: PropTypes.objectOf(object),
};

SideInfo.defaultProps = {
  trucker: {},
};

export default SideInfo;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { message, Table, Tooltip } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import client from '../../../../api/api';
import { getSessionUserData } from '../../../../libs/user';
import SettlementAdvanceEnabled from '../SettlementAdvanceEnabled';
import useSearchParams from '../../../Global/Antd/Table/useSearchParams';
import SearchColumn from '../../../Global/Antd/Table/searchColumn';
import useDocumentsModal from './useDocumentsModal';

const IconWrap = styled.div`
  button {
    color: #6495ed;
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
  }

  button:focus {
    outline: none;
  }
`;

const browserLocale = navigator.language || 'es-AR';

const CarrierRecordBillingTable2 = ({ advanceEnabled }) => {
  const [invoices, setInvoices] = useState();
  const [loading, setIsLoading] = useState(false);
  const [paginationConfiguration, setPaginationConfiguration] = useState();
  const [order, setOrder] = useState({});
  const [searchParams, setSearchParams] = useState([]);
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    pageSize: 10,
  });
  const [queryParams, handleColumnSearch, handleResetColumnSearch, orderParams] = useSearchParams(
    searchParams,
    setSearchParams
  );
  const { modal: documentsModal, openModal: openDocumentsModal } = useDocumentsModal();

  const columns = [
    {
      title: 'Acciones',
      align: 'center',
      key: 'acciones',
      render: record => {
        const { canAskAdvance } = record;
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Tooltip title="Ver documentos">
              <IconWrap>
                <button type="button" onClick={() => openDocumentsModal(record.invoiceNumber)}>
                  <img src="../../../images/icons/document.png" alt="document-icon" />
                </button>
              </IconWrap>
            </Tooltip>
            {advanceEnabled && canAskAdvance ? (
              <SettlementAdvanceEnabled billingId={record.id} setErrorMsg={message.error} />
            ) : null}
          </div>
        );
      },
    },
    {
      title: 'Factura',
      align: 'center',
      key: 'invoiceNumber',
      sorter: true,
      render: record => {
        return (
          <div>
            {record.invoiceType} {record.invoiceNumber}
          </div>
        );
      },
      ...SearchColumn('invoiceNumber', handleColumnSearch, handleResetColumnSearch),
    },
    {
      title: 'Primer Vencimiento',
      align: 'center',
      key: 'dueDate',
      dataIndex: 'dueDate',
      sorter: true,
      render: value => {
        return value
          ? moment(value)
              .locale(browserLocale)
              .format('DD-MM-YYYY')
          : '';
      },
    },
    {
      title: 'Primer Pago',
      align: 'center',
      key: 'paymentDay',
      sorter: true,
      render: record => {
        const { paymentDay } = record;
        return paymentDay
          ? moment(paymentDay)
              .locale(browserLocale)
              .format('DD-MM-YYYY')
          : '';
      },
    },
    {
      title: 'Porcentaje',
      align: 'center',
      dataIndex: 'firstPaymentPercentage',
      key: 'firstPaymentPercentage',
    },
    {
      title: 'Segundo Vencimiento',
      align: 'center',
      key: 'secondDueDate',
      sorter: true,
      render: record => {
        const { secondDueDate } = record;
        return secondDueDate
          ? moment(secondDueDate)
              .locale(browserLocale)
              .format('DD-MM-YYYY')
          : 'N/A';
      },
    },
    {
      title: 'Segundo Pago',
      align: 'center',
      key: 'secondPaymentDay',
      sorter: true,
      render: record => {
        const { secondPaymentDay } = record;
        return secondPaymentDay
          ? moment(secondPaymentDay)
              .locale(browserLocale)
              .format('DD-MM-YYYY')
          : '';
      },
    },
    {
      title: 'Porcentaje',
      align: 'center',
      dataIndex: 'secondPaymentPercentage',
      key: 'secondPaymentPercentage',
    },
    {
      title: 'Ajuste',
      align: 'right',
      key: 'adjustmentAmount',
      dataIndex: 'adjustmentAmount',
      render: value =>
        new Intl.NumberFormat(browserLocale, {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol',
        }).format(value),
    },
    {
      title: 'Neto',
      align: 'right',
      key: 'netAmount',
      dataIndex: 'netAmount',
      sorter: true,
      render: value =>
        new Intl.NumberFormat(browserLocale, {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol',
        }).format(value),
    },
    {
      title: 'IVA',
      align: 'right',
      key: 'ivaAmount',
      dataIndex: 'ivaAmount',
      render: value =>
        new Intl.NumberFormat(browserLocale, {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol',
        }).format(value),
    },
    {
      title: 'Líquido Producto',
      align: 'right',
      key: 'liquidProductAmount',
      dataIndex: 'liquidProductAmount',
      render: value =>
        new Intl.NumberFormat(browserLocale, {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol',
        }).format(value),
    },
    {
      title: 'Gasoil',
      align: 'right',
      key: 'gasoilAmount',
      dataIndex: 'gasoilAmount',
      sorter: true,
      render: value =>
        new Intl.NumberFormat(browserLocale, {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol',
        }).format(value),
    },
    {
      title: 'Adelanto Bamba',
      align: 'right',
      key: 'bambaAmount',
      dataIndex: 'bambaAmount',
      sorter: true,
      render: value =>
        new Intl.NumberFormat(browserLocale, {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol',
        }).format(value),
    },
    {
      title: 'Final',
      align: 'right',
      key: 'finalAmount',
      dataIndex: 'finalAmount',
      sorter: true,
      render: value =>
        new Intl.NumberFormat(browserLocale, {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'narrowSymbol',
        }).format(value),
    },
  ];

  const fetchInvoices = useCallback(async () => {
    try {
      setIsLoading(true);
      const t = queryParams(order, paginationParams);
      const res = await client.endpoints.carrier.getInvoices2(
        // eslint-disable-next-line no-underscore-dangle
        { ownerId: getSessionUserData().data._id, ...t }
      );
      setPaginationConfiguration({
        position: ['bottomLeft'],
        total: res.data.count,
        showTotal: total => `Total de ${total} items`,
        pageSize: res.data.limit,
        pageSizeOptions: ['10', '20', '50'],
        current: res.data.page,
        showSizeChanger: true,
      });
      setInvoices(res.data.docs);
    } catch (error) {
      message.error('Error al cargar las facturas');
    } finally {
      setIsLoading(false);
    }
  }, [order, paginationParams]);

  useEffect(() => {
    fetchInvoices();
  }, [paginationParams]);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.column) {
      setOrder(orderParams(sorter));
    }
    setPaginationParams({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  return (
    <>
      <Table
        style={{ paddingLeft: '8px' }}
        columns={columns}
        dataSource={invoices}
        loading={loading}
        pagination={paginationConfiguration}
        onChange={handleTableChange}
      />
      {documentsModal}
    </>
  );
};
CarrierRecordBillingTable2.propTypes = {
  advanceEnabled: PropTypes.bool.isRequired,
};

export default CarrierRecordBillingTable2;

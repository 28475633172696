/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { message, Modal, Typography, Divider, Button } from 'antd';
import client from '../../../../api/api';

const { Text } = Typography;

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const Loading = styled.div`
  p.loading-list {
    font-size: 14px;
    color: #ffbb0f;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const Truckers = styled.div`
  .ad-trucker-assignment {
    position: absolute;
    left: 15px;
    width: 93%;
    background: #ffbb0f;
    padding: 20px;
    border-radius: 12px;
  }

  .ad-trucker-assignment h4 {
    color: #1c2023;
  }

  .ad-trucker-assignment p {
    color: gray;
    width: 100%;
  }
  .ad-trucker-assignment button {
    border-radius: 40px;
    background: #1c2023;
    color: #ffbb0f;
    margin-top: 10px;
    padding: 2px 30px;
    border: none;
  }

  .header {
    width: 100%;
    padding: 12px 20px 0;
    color: #ffbb0f;
    text-align: center;
  }

  ${media.desktop} {
    .ad-trucker-assignment {
      width: 300px;
      left: 20%;
    }

    .header {
      display: none;
    }
  }
`;

const TruckerListMobile = styled.ul`
  background: #1c2023;
  overflow-x: auto;
  list-style: none;
  white-space: nowrap;
  padding: 10px;

  h1 {
    color: #ffbb0f;
    margin-left: 12px;
  }

  ${media.desktop} {
    display: none;
  }
`;

const Headers = styled.div`
  display: none;
  ${media.desktop} {
    display: block;
    font-size: 12px;
    border-bottom: 1px solid #ffbb0f;
    margin-top: 30px;

    .humber-yellow {
      color: #ffbb0f;
    }

    p {
      display: inline-block;
      color: gray;
    }

    p.trucker-cuit {
      width: 88px;
      margin-left: 11px;
    }

    p.trucker-name {
      width: 200px;
      margin-left: 10px;
    }

    p.trucker-cel {
      width: 80px;
    }

    p.truck-license {
      width: 64px;
    }

    p.trailer-license {
      width: 66px;
    }

    p.truck-type {
      width: 100px;
    }

    p.status {
      width: 74px;
      text-align: center;
    }
  }
`;

const TruckerListDesktop = styled.ul`
  display: none;

  .humber-yellow {
    color: #ffbb0f;
  }

  ${media.desktop} {
    display: block;
    width: 95%;
    margin: 0 auto;

    li {
      padding: 5px 0;
      border-bottom: 1px solid gray;
    }

    li p {
      display: inline-block;
      font-size: 12px;
    }

    li p.trucker-premium {
      padding: 0 28px 0 15px;
    }

    li p.trucker-premium .premium {
      color: #ffbb0f;
    }

    li p.trucker-premium .no-premium {
      color: gray;
    }

    li p.trucker-name-value {
      width: 210px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: sub;
    }

    li p.trucker-cuit {
      width: 88px;
    }

    li p.trucker-cel-value {
      width: 80px;
    }

    li p.truck-patent,
    li p.trailer-patent {
      width: 68px;
    }

    li p.truck-body-type {
      width: 97px;
    }

    li p.trucker-status {
      text-align: center;
      width: 90px;
    }

    li .btn-add-disabled,
    li .btn-add {
      border: none;
      font-size: 12px;
      padding: 0 17px;
      background: #ffbb0f;
      outline: none;
    }

    li .btn-add-disabled {
      background: #30354e;
      color: gray;
    }

    li p .available {
      color: #19c482;
    }

    li p .on-the-road {
      color: #e25555;
    }
  }
`;

const Trucker = styled.li`
  display: inline-block;
  background: #333750;
  padding: 16px 26px 32px;
  margin: 30px 10px;
  border-radius: 22px;
  width: 240px;
  cursor: pointer;

  .humber-yellow {
    color: #ffbb0f;
  }

  .trucker-header h4,
  .premium-wrap {
    display: inline-block;
  }

  .premium-wrap {
    float: right;
  }

  .premium-wrap .premium {
    color: #ffbb0f;
    font-size: 20px;
  }

  .premium-wrap .no-premium {
    color: #1c2023;
    font-size: 20px;
  }

  h4.available {
    color: #19c482;
    font-weight: 300;
  }

  h4.not-available {
    color: red;
    font-weight: 300;
  }

  p {
    color: white;
  }

  p.trucker-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  label {
    color: gray;
    font-size: 12px;
    margin: 0%;
  }

  .available-wrap {
    width: 100%;
    text-align: right;
    padding-bottom: 10px;
  }

  .available-wrap .available {
    color: #ffbb0f;
    font-size: 20px;
  }
`;

const TruckerList = ({ load }) => {
  const { id: loadId, mandatoryBackgroundLocation, relatedLoads, from, to } = load;
  const [truckerList, setTruckerList] = useState(undefined);
  const [originalTrucker, setOriginalTrucker] = useState('');
  const [filterTrucker, setFilterTrucker] = useState('');
  const [truckerIdSelected, setTruckerIdSelected] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [carrierEnabledToAssign, setCarrierEnabledToAssign] = useState(undefined);
  const [carrierEnabledToAssignLoaded, setCarrierEnabledToAssignLoaded] = useState(false);
  const [loadEnabledTruckers, setLoadEnabledTruckers] = useState(false);
  const [truckPlate, setTruckPlate] = useState('');
  const [assingTruckerVisible, setAssingTruckerVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchCarrierTruckers = useCallback(async () => {
    try {
      const res = await client.endpoints.carrier.getTruckers();
      setTruckerList(res.data);
      setOriginalTrucker(res.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getCarrierEnabledForLoad = useCallback(async () => {
    try {
      const res = await client.endpoints.carrier.getCarrierEnabledForLoad(loadId);
      setCarrierEnabledToAssign(!!res.data);
    } catch (error) {
      if (error.response?.status === 404 && error.response.data === 'Carrier no habilitado') {
        console.warn(`El carrier no se encuentra habilitado para asignar esta carga(${loadId})`);
        setCarrierEnabledToAssign(false);
      } else {
        console.error(error);
        setErrorMsg('Hubo un error al buscar si el transportista está disponible o no');
      }
    } finally {
      setCarrierEnabledToAssignLoaded(true);
    }
  }, []);

  useEffect(() => {
    fetchCarrierTruckers();
    getCarrierEnabledForLoad();
  }, [fetchCarrierTruckers, getCarrierEnabledForLoad]);

  useEffect(() => {
    if (errorMsg) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [errorMsg]);

  useEffect(() => {
    if (filterTrucker && originalTrucker) {
      setTruckerList(
        originalTrucker.filter(trucker => {
          return (
            trucker.truckerName.toLowerCase().indexOf(filterTrucker.toLowerCase()) !== -1 ||
            trucker.truckerCuit.indexOf(filterTrucker) !== -1
          );
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTrucker]);

  const isTruckerEnabledForLoad = useCallback(async truckerId => {
    try {
      const res = await client.endpoints.carrier.getTruckerEnabledForLoad(loadId, truckerId);

      return !!res.data;
    } catch (error) {
      if (error.response?.status === 404 && error.response.data === 'Trucker no habilitado') {
        return false;
      }

      console.error(error);
      setErrorMsg('Hubo un error al buscar si el camionero está disponible o no');
    }

    return false;
  }, []);

  const markEnabledTruckers = useCallback(async () => {
    const promises = truckerList.map(async trucker => {
      const { _id, ...rest } = trucker;

      if (carrierEnabledToAssign) {
        return {
          _id,
          enabledtoAssign: true,
          ...rest,
        };
      }

      const enabledtoAssign = await isTruckerEnabledForLoad(_id);
      return {
        _id,
        enabledtoAssign,
        ...rest,
      };
    });

    const newTruckerList = await Promise.all(promises);

    setLoadEnabledTruckers(true);
    setTruckerList(newTruckerList);
    setOriginalTrucker(newTruckerList);
  }, [truckerList, carrierEnabledToAssign, isTruckerEnabledForLoad]);

  useEffect(() => {
    if (!loadEnabledTruckers && carrierEnabledToAssignLoaded && truckerList) {
      markEnabledTruckers();
    }
  }, [loadEnabledTruckers, carrierEnabledToAssignLoaded, truckerList, markEnabledTruckers]);

  const renderStatus = useCallback(
    (trucker, isDesktop) => {
      const notAvailable = isDesktop ? 'on-the-road' : 'not-available';

      let className;
      let text;

      if (trucker.premium) {
        className = trucker.onTheRoad || !trucker.enabledtoAssign ? notAvailable : 'available';

        if (trucker.onTheRoad) {
          text = 'En viaje';
        } else {
          text = !trucker.enabledtoAssign ? 'No disponible' : 'Disponible';
        }
      } else {
        className = 'humber-yellow';
        text = 'No premium';
      }

      return isDesktop ? (
        <span className={className}>{text}</span>
      ) : (
        <h4 className={className}>{text}</h4>
      );
    },
    [truckerList]
  );

  const assignRelatedLoads = useCallback(
    async truckersId => {
      try {
        // Se asignan, de forma secuencial y en orden, las cargas enganchadas relacionadas.-
        for (let i = 0; i < relatedLoads.length; i += 1) {
          const relatedLoadId = relatedLoads[i];

          // Se descarta la actual (previamente asignada).-
          if (relatedLoadId !== loadId) {
            // eslint-disable-next-line no-await-in-loop
            await client.endpoints.carrier.takeLoad(relatedLoadId, truckersId);
          }
        }
      } catch (error) {
        console.warn('Error al asignar alguna de las cargas enganchadas: ', relatedLoads);
        console.error(error);
      }
    },
    [loadId, relatedLoads]
  );

  const sendWapp = async userId => {
    try {
      await client.endpoints.carrier.sendWappTrucker(userId, from, to);
    } catch (error) {
      setErrorMsg(error);
    }
  };

  const isLoadMandatoryBackgroundLocation = async truckerId => {
    if (mandatoryBackgroundLocation) {
      const { data: backgroundLocation } = await client.endpoints.carrier.getBackgroundLocation(
        truckerId
      );
      return !!backgroundLocation;
    }
    return false;
  };

  const vtvUoToDate = async plate => {
    const { data } = await client.endpoints.carrier.truckerHasVtvUpToDate({
      plate,
      loadId,
    });
    return data;
  };

  const assignTruckerToLoad = async truckerId => {
    try {
      const takeLoadResponse = await client.endpoints.carrier.takeLoad(loadId, [truckerId]);
      if (takeLoadResponse.status === 200) {
        // Se asignan también las cargas enganchadas, si las tuviera.-
        await assignRelatedLoads([truckerId]);
        setTruckerList(truckerList.filter(trucker => trucker._id !== [truckerId][0]));
      }
    } catch (error) {
      message.error(
        error.response.data.message || 'Hubo un error al asignar el camionero seleccionado'
      );
    }
  };

  const handleOnOk = useCallback(
    async (truckerId, plate) => {
      try {
        setLoading(true);
        await isLoadMandatoryBackgroundLocation(truckerId);
        const checkVtvUoToDate = await vtvUoToDate(plate);

        if (checkVtvUoToDate) {
          await assignTruckerToLoad(truckerId);
        } else {
          message.error(`La VTV de la patente ${plate}, no se encuentra al día`);
        }
        sendWapp(truckerId);
        setAssingTruckerVisible(false);
      } catch (error) {
        message.error('Hubo un error al asignar al camionero.');
      } finally {
        setLoading(false);
      }
    },
    [assingTruckerVisible]
  );

  const handleClose = () => {
    setAssingTruckerVisible(false);
  };

  const renderAssignButton = useCallback(
    trucker => {
      if (!trucker.premium) {
        return '';
      }

      return (
        <Button
          className={trucker.onTheRoad || !trucker.enabledtoAssign ? 'btn-add-disabled' : 'btn-add'}
          disabled={trucker.onTheRoad || !trucker.enabledtoAssign}
          onClick={async () => {
            setAssingTruckerVisible(true);
            setTruckerIdSelected(trucker._id);
            setTruckPlate(trucker.truck?.truckPatent);
          }}
        >
          Asignar
        </Button>
      );
    },
    [truckerList]
  );

  return (
    <>
      <Truckers>
        <div className="humber-input filter-wrapper">
          <label htmlFor="search">
            Buscar
            <input
              id="search"
              type="text"
              className="form-control"
              value={filterTrucker}
              onChange={event => setFilterTrucker(event.target.value)}
            />
          </label>
        </div>
        {truckerList ? (
          <>
            {errorMsg !== '' ? <p className="error-msg">{errorMsg}</p> : ''}
            <TruckerListMobile>
              <h1>Mi flota</h1>
              {truckerList.map(trucker => (
                <Trucker
                  key={trucker._id}
                  onClick={async () => {
                    setAssingTruckerVisible(true);
                    setTruckerIdSelected(trucker._id);
                    setTruckPlate(trucker.truck?.truckPatent);
                  }}
                >
                  <div className="trucker-header">
                    {renderStatus(trucker, false)}
                    <div className="premium-wrap">
                      <p>
                        <FontAwesomeIcon
                          className={trucker.premium ? 'premium humber-yellow' : 'no-premium'}
                          icon={faCrown}
                        />
                      </p>
                    </div>
                  </div>
                  <label>Chofer</label>
                  <p className="trucker-name">{trucker.truckerName}</p>
                  <label>CUIT Chofer</label>
                  <p>{trucker.truckerCuit}</p>
                  <label>Cel</label>
                  <p>{trucker.truckerCellPhone}</p>
                  <label>Tractor</label>
                  <p>{trucker.truck ? trucker.truck.truckPatent : 'Sin camión'}</p>
                  <label>Remolque</label>
                  <p>{trucker.truck ? trucker.truck.trailerPatent : 'Sin trailer'}</p>
                  <label>Carrocería</label>
                  <p>{trucker.truck ? trucker.truck.truckBodyType : 'Sin tipo de carrocería'}</p>
                </Trucker>
              ))}
            </TruckerListMobile>
            <TruckerListDesktop>
              <Headers>
                <p className="trucker-premium">Premium</p>
                <p className="trucker-name">Chofer</p>
                <p className="trucker-cuit">CUIT Chofer</p>
                <p className="trucker-cel">Cel</p>
                <p className="truck-license">Tractor</p>
                <p className="trailer-license">Remolque</p>
                <p className="truck-type">Carrocería</p>
                <p className="status">Status</p>
              </Headers>
              {truckerList.map(tr => (
                <li key={tr._id}>
                  <p className="trucker-premium">
                    <FontAwesomeIcon
                      className={tr.premium ? 'premium humber-yellow' : 'no-premium'}
                      icon={faCrown}
                    />
                  </p>
                  <p className="trucker-name-value">{tr.truckerName}</p>
                  <p className="trucker-cuit">{tr.truckerCuit}</p>
                  <p className="trucker-cel-value">{tr.truckerCellPhone}</p>
                  <p className="truck-patent">{tr.truck ? tr.truck.truckPatent : 'Sin camión'}</p>
                  <p className="trailer-patent">
                    {tr.truck ? tr.truck.trailerPatent : 'Sintrailer'}
                  </p>
                  <p className="truck-body-type">{tr.truck ? tr.truck.truckBodyType : '...'}</p>
                  <p className="trucker-status">{renderStatus(tr, true)}</p>
                  {renderAssignButton(tr)}
                </li>
              ))}
            </TruckerListDesktop>
          </>
        ) : (
          <Loading>
            <p className="loading-list">Cargando listado de camioneros...</p>
          </Loading>
        )}
      </Truckers>

      <Modal
        destroyOnClose
        title="Asignar camionero"
        visible={assingTruckerVisible}
        footer={[
          <Button key="back" onClick={() => handleClose()}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => handleOnOk(truckerIdSelected, truckPlate)}
          >
            Aceptar
          </Button>,
        ]}
      >
        <Text>Al seleccionar un camionero te estás comprometiendo a realizar el viaje.</Text>
        <Text> Se cobrará recargo por asignar un chofer y no realizar el viaje.</Text>
        <br />
        <Text>Si tenes dudas, contactanos.</Text>
        <br />
        <Divider />
        <Text>
          Al aceptar se enviará un mensaje de WhatsApp al camionero para que confirme si los datos
          de facturación y la patente son correctos.
        </Text>
      </Modal>
    </>
  );
};

TruckerList.propTypes = {
  load: PropTypes.shape({
    id: PropTypes.string,
    mandatoryBackgroundLocation: PropTypes.bool,
    relatedLoads: PropTypes.arrayOf(PropTypes.string).isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
};

export default TruckerList;

import { Button, Collapse, message, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import client from '../../../../api/api';
import { getSessionUserData } from '../../../../libs/user';

const PanelContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

// Styled Button to ensure text wraps
const StyledButton = styled(Button)`
  white-space: normal;
  word-break: break-word;
  text-align: left;
  padding: 4px 8px;
  display: block;
  margin-bottom: 8px;
`;

const useDocumentsModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [settlement, setSettlement] = useState('');
  const [invoiceFile, setInvoiceFile] = useState('');
  const [debitNotes, setDebitNotes] = useState([]);
  const [creditNotes, setCreditNotes] = useState([]);
  const [gasoilInvoices, setGasoilInvoices] = useState([]);
  const [collapseList, setCollapseList] = useState([]);

  const fetchDocuments = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.endpoints.carrier.getInvoices2({
        // eslint-disable-next-line no-underscore-dangle
        ownerId: getSessionUserData().data._id,
        invoiceNumber,
      });
      const invoice = data.docs[0];
      setSettlement(invoice.settlementPdfFile);
      setInvoiceFile(invoice.invoicePdfFile);
      if (invoice.gasoilInvoicesFiles) {
        setGasoilInvoices(invoice.gasoilInvoicesFiles.split(','));
      }
      if (invoice.debitNotesFiles) {
        setDebitNotes(invoice.debitNotesFiles.split(','));
      }
      if (invoice.creditNotesFiles) {
        setCreditNotes(invoice.creditNotesFiles.split(','));
      }
    } catch (error) {
      message.error('Error al obtener los documentos');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = async invoice => {
    setInvoiceNumber(invoice);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCollapseList([]);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen && invoiceNumber) {
      fetchDocuments();
    }
  }, [isModalOpen, invoiceNumber]);

  const handleFileDownload = async (getFileMethod, file) => {
    try {
      setFileLoading(true);
      const { data } = await getFileMethod(file);
      const url = URL.createObjectURL(data);
      window.open(url);
    } catch (e) {
      console.error(e);
      message.error('Error al descargar el archivo');
    } finally {
      setFileLoading(false);
    }
  };

  useEffect(() => {
    const list = collapseList;
    if (settlement) {
      list.push({
        key: 1,
        label: 'Liquidación',
        children: (
          <PanelContentWrapper>
            <StyledButton
              type="link"
              onClick={() =>
                handleFileDownload(client.endpoints.carrier.getSettlementFile, settlement)
              }
              target="_blank"
            >
              {settlement.substring(settlement.indexOf('_20') + 1)}
            </StyledButton>
          </PanelContentWrapper>
        ),
      });
    }
    setCollapseList(list);
  }, [settlement, setCollapseList]);

  useEffect(() => {
    const list = collapseList;
    if (invoiceFile) {
      list.push({
        key: 2,
        label: 'Factura',
        children: (
          <PanelContentWrapper>
            <StyledButton
              type="link"
              onClick={() =>
                handleFileDownload(client.endpoints.carrier.getInvoiceFiles, invoiceFile)
              }
              target="_blank"
            >
              {invoiceFile.substring(settlement.indexOf('_20') + 1)}
            </StyledButton>
          </PanelContentWrapper>
        ),
      });
    }
    setCollapseList(list);
  }, [invoiceFile, setCollapseList]);

  useEffect(() => {
    const list = collapseList;
    if (debitNotes.length) {
      list.push({
        key: 3,
        label: 'Notas de débito',
        children: debitNotes.map(debitNote => (
          <PanelContentWrapper>
            <StyledButton
              key={debitNote}
              type="link"
              onClick={() =>
                handleFileDownload(client.endpoints.carrier.getDebitNotesFiles, debitNote)
              }
              target="_blank"
            >
              {debitNote.substring(settlement.indexOf('_20') + 1)}
            </StyledButton>
          </PanelContentWrapper>
        )),
      });
    }
    setCollapseList(list);
  }, [debitNotes, setCollapseList]);

  useEffect(() => {
    const list = collapseList;
    if (creditNotes.length) {
      list.push({
        key: 4,
        label: 'Notas de crédito',
        children: creditNotes.map(creditNote => (
          <PanelContentWrapper>
            <StyledButton
              key={creditNote}
              type="link"
              onClick={() =>
                handleFileDownload(client.endpoints.carrier.getDebitNotesFiles, creditNote)
              }
              target="_blank"
            >
              {creditNote.substring(settlement.indexOf('_20') + 1)}
            </StyledButton>
          </PanelContentWrapper>
        )),
      });
    }
    setCollapseList(list);
  }, [creditNotes, setCollapseList]);

  useEffect(() => {
    const list = collapseList;
    if (gasoilInvoices.length) {
      list.push({
        key: 5,
        label: 'Facturas de gasoil',
        children: gasoilInvoices.map(gasoilInvoice => (
          <PanelContentWrapper>
            <StyledButton
              key={gasoilInvoice}
              type="link"
              onClick={() =>
                handleFileDownload(client.endpoints.carrier.getInvoiceFiles, gasoilInvoice)
              }
              target="_blank"
            >
              {gasoilInvoice.substring(settlement.indexOf('_20') + 1)}
            </StyledButton>
          </PanelContentWrapper>
        )),
      });
    }
    setCollapseList(list);
  }, [gasoilInvoices, setCollapseList]);

  const modal = (
    <Modal
      visible={isModalOpen}
      onCancel={closeModal}
      title={`Documentos de la factura ${invoiceNumber}`}
      destroyOnClose
    >
      {isLoading && <Spin spinning={isLoading} />}
      {!isLoading && (
        <Collapse>
          {collapseList.map(item => (
            <Collapse.Panel header={item.label} key={item.key}>
              {item.children}
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
      <Spin spinning={fileLoading} />
    </Modal>
  );

  return { modal, openModal, closeModal };
};

export default useDocumentsModal;

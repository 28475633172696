/* eslint-disable react/jsx-props-no-spreading */
import { DownloadIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import client from '../../../api/api';
import toCurrency from '../../../libs/currencyUtils';

const CheckingAccountTable = ({ data }) => {
  const toast = useToast();
  const downloadTostRef = useRef();

  const downloadDocument = record => {
    downloadTostRef.current = toast({
      description: 'El comprobante se esta descargando. Aguarde unos instantes.',
      status: 'info',
      position: 'bottom',
      variant: 'solid',
    });
    client.endpoints.carrier
      .getDocumentToDownload(
        record.documentInformation.document,
        record.documentInformation.impresionName
      )
      .then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' });

        const downloadUrl = window.URL.createObjectURL(file);

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', record.documentInformation.description1);

        document.body.appendChild(link);

        link.click();

        link.remove();
      })
      .catch(error => {
        console.error(error);
        toast({
          description: 'Ocurrió un error abrir el documento',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
          variant: 'solid',
        });
      })
      .finally(() => {
        if (downloadTostRef.current) {
          toast.close(downloadTostRef.current);
        }
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Fecha',
        accessor: original => {
          return new Date(original.date).toLocaleDateString();
        },
        minWidth: 150,
      },
      {
        Header: 'Movimiento',
        width: 400,
        accessor: original => {
          if (original.documentInformation) {
            return (
              <VStack alignItems="start" width="100%">
                <Text as="strong">{original.documentInformation.description1}</Text>
                <Text>{original.documentInformation.description2}</Text>
              </VStack>
            );
          }
          return null;
        },
      },
      {
        Header: 'Importe',
        accessor: original => {
          return <Text textAlign="end">{toCurrency(original.amount)}</Text>;
        },
      },
      {
        Header: 'Comprobante',
        accesor: 'file',
        id: 'file',
        width: 150,
        accessor: original => {
          return original && original.documentInformation.document ? (
            <IconButton
              icon={
                <DownloadIcon
                  onClick={() => {
                    downloadDocument(original);
                  }}
                  w={6}
                  h={6}
                />
              }
            />
          ) : (
            ''
          );
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });
  return (
    <>
      <Table {...getTableProps()} fontSize="1xl" mt={4}>
        <Thead>
          {headerGroups.map(headerGroup => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Th fontSize="1xl">{column.render('Header')}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.length === 0 && 'No se encontraron datos'}
          {rows &&
            rows.map(row => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <Td
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                      width={cell.column.width}
                    >
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </>
  );
};
CheckingAccountTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      documentInformation: PropTypes.shape({
        document: PropTypes.string,
        impresionName: PropTypes.string,
        description1: PropTypes.string,
        description2: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default React.memo(CheckingAccountTable, (prev, post) => {
  return prev.data === post.data;
});

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SearchColumn = (columnName, handleSearch, handleReset, hide) => {
  const searchInputHolder = useRef(null);

  return {
    ...(!hide && {
      // eslint-disable-next-line react/prop-types
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputHolder}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys[0], confirm, columnName)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys[0], confirm, columnName)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => handleReset(columnName, clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => (
        <span>
          <SearchOutlined style={{ color: filtered ? '#000000' : undefined }} />
          {filtered && '(filtrado)'}
        </span>
      ),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInputHolder.current?.select(), 100);
        }
      },
    }),
  };
};

SearchColumn.propTypes = {
  setSelectedKeys: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  selectedKeys: PropTypes.array,
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
  columnName: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  hide: PropTypes.bool,
};

export default SearchColumn;

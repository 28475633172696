import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import client from '../../../api/api';
/* eslint-disable no-underscore-dangle */

const SettlementAdvanceEnabledWrap = styled.div``;

const IconWrap = styled.div`
  button {
    color: #6495ed;
    border: none;
    padding: 0;
    margin: 0;
    background: transparent;
  }

  button:focus {
    outline: none;
  }
`;

const SettlementAdvanceEnabled = ({ billingId, setErrorMsg }) => {
  const getAdvanceURL = async id => {
    try {
      const res = await client.endpoints.carrier.advanceUrlCarrier(id);
      window.open(res.data.url, '_blank');
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  return (
    <SettlementAdvanceEnabledWrap>
      <IconWrap>
        <button
          type="button"
          onClick={() => {
            getAdvanceURL(billingId);
          }}
        >
          <img src="../../../images/icons/adelanto.png" alt="adelanto" />
        </button>
      </IconWrap>
    </SettlementAdvanceEnabledWrap>
  );
};

SettlementAdvanceEnabled.propTypes = {
  billingId: PropTypes.string.isRequired,
  setErrorMsg: PropTypes.func.isRequired,
};

export default SettlementAdvanceEnabled;

import {
  Box,
  Container,
  Heading,
  HStack,
  Select,
  Skeleton,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import client from '../../../api/api';
import toCurrency from '../../../libs/currencyUtils';
import CheckingAccountTable from './CheckingAccountTable';
import Filter from './Filter';

const CheckingAccount = () => {
  const toast = useToast();
  const [billingDataOptions, setBillingDataOptions] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [isLoadingBillingData, setIsLoadingBillingData] = useState();

  const [billingDataSelected, setBillingDataSelected] = useState();
  const [checkingBalanceData, setCheckingBalanceData] = useState([]);
  const [filters, setFilters] = useState(['all']);
  const [filtersComposition, setFiltersComposition] = useState(['all']);
  const [filteredCheckingBalanceData, setFilteredCheckingBalanceData] = useState([]);
  const [filteredCompositionData, setFilteredCompositionData] = useState([]);
  const [documentNumberSearch, setDocumentNumberSearch] = useState();
  const [documentNumberCompositionSearch, setDocumentNumberCompositionSearch] = useState();
  const [totalBalance, setTotalBalance] = useState();

  const [compositionBalanceData, setCompositionBalanceData] = useState([]);

  const data = useMemo(() => filteredCheckingBalanceData, [filteredCheckingBalanceData]);
  const compositionData = useMemo(() => filteredCompositionData, [filteredCompositionData]);

  const loadBillingDataOptions = async () => {
    try {
      setIsLoadingBillingData(true);
      const response = await client.endpoints.carrier.getBillingDataOptions();
      if (response.data.length === 0) {
        toast({
          description: 'No tenes facturas para ver.',
          status: 'info',
          duration: 9000,
          isClosable: true,
          position: 'top',
          variant: 'solid',
        });
      }
      setBillingDataOptions(response.data);
      if (response.data && response.data[0]) {
        setTotalBalance(response.data[0].totalBalance);
      }
    } catch (error) {
      toast({
        description: 'Ocurrió un error al cargar las razones sociales',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
        variant: 'solid',
      });
      setBillingDataOptions([]);
    } finally {
      setIsLoadingBillingData(false);
      setIsLoading(false);
    }
  };

  const loadAccountBalance = () => {
    setIsLoading(true);
    // setTotalBalance(0);
    setCheckingBalanceData([]);
    client.endpoints.carrier
      .getCheckingAccountData(billingDataSelected.cuit, null)
      .then(response => {
        // setTotalBalance(response.data.totalBalance);
        setCheckingBalanceData(response.data.data);
        setFilteredCheckingBalanceData(response.data.data);
      })
      .catch(error => {
        console.error(error);
        toast({
          description: 'Ocurrió un error al cargar los datos movimientos',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
          variant: 'solid',
        });
        setCheckingBalanceData([]);
        setFilteredCheckingBalanceData([]);
        // setTotalBalance(0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadBalanceComposition = () => {
    setIsLoading(true);
    client.endpoints.carrier
      .getBalanceCompositionData(billingDataSelected.cuit)
      .then(response => {
        setCompositionBalanceData(response.data);
        setFilteredCompositionData(response.data);
      })
      .catch(error => {
        console.error(error);
        toast({
          description: 'Ocurrió un error al cargar los datos de Pendientes de cobro/pago',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top',
          variant: 'solid',
        });
        setCompositionBalanceData([]);
        setFilteredCompositionData([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (billingDataSelected) {
      loadAccountBalance();
      loadBalanceComposition();
      setFiltersComposition(['all']);
      setFilters(['all']);
    }
  }, [billingDataSelected]);

  useEffect(() => {
    setIsLoading(true);
    loadBillingDataOptions();
  }, []);

  useEffect(() => {
    if (billingDataOptions && billingDataOptions.length > 0) {
      setBillingDataSelected(billingDataOptions[0]);
    }
  }, [billingDataOptions]);

  function onBillingDataChange(e) {
    const selectedBilingData = JSON.parse(e.target.value);
    setBillingDataSelected(selectedBilingData);
    setTotalBalance(selectedBilingData.totalBalance);
  }

  const updateFilters = (documentType, enabled) => {
    if (enabled) {
      filters.push(documentType);
    } else {
      filters.splice(filters.findIndex(x => x === documentType), 1);
    }
    setFilters([...filters]);
  };

  const searchCheckingBalanceData = () => {
    if (filters.find(x => x === 'all')) {
      setFilteredCheckingBalanceData([...checkingBalanceData]);
    } else {
      const filtered = checkingBalanceData.filter(d => {
        const hasFilter =
          filters.length === 0 ||
          filters.some(filter => d.documentInformation && filter === d.documentInformation.type);
        let hasDocumentNumber = true;
        if (documentNumberSearch && documentNumberSearch.length > 0) {
          hasDocumentNumber = d.documentNumber.search(documentNumberSearch) >= 0;
        }
        return hasFilter && hasDocumentNumber;
      });
      setFilteredCheckingBalanceData([...filtered]);
    }
  };

  const updateFiltersComposition = (documentType, enabled) => {
    if (enabled) {
      filtersComposition.push(documentType);
    } else {
      filtersComposition.splice(filtersComposition.findIndex(x => x === documentType), 1);
    }
    setFiltersComposition([...filtersComposition]);
  };

  const searchCompositionData = () => {
    if (filtersComposition.find(x => x === 'all')) {
      setFilteredCompositionData([...compositionBalanceData]);
    } else {
      const filtered = compositionBalanceData.filter(d => {
        const hasFilter =
          filtersComposition.length === 0 ||
          filtersComposition.some(
            filter => d.documentInformation && filter === d.documentInformation.type
          );
        let hasDocumentNumber = true;
        if (documentNumberCompositionSearch && documentNumberCompositionSearch.length > 0) {
          hasDocumentNumber = d.documentNumber.search(documentNumberCompositionSearch) >= 0;
        }
        return hasFilter && hasDocumentNumber;
      });
      setFilteredCompositionData([...filtered]);
    }
  };

  return (
    <>
      <Container marginTop="1em" marginLeft="1em" maxW="98%">
        <VStack>
          <Stack
            borderWidth="1px"
            borderRadius="lg"
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow="2xl"
            color="white"
            padding={4}
            w="100%"
          >
            <Heading
              fontWeight={600}
              mb="10px"
              fontFamily="var(--chakra-fonts-heading)"
              fontSize="4xl"
              align="left"
              lineHeight="110%"
              color="white"
            >
              <Text as="span">Resumen de cuenta corriente</Text>
            </Heading>
            <Text fontSize="3xl">Razon social</Text>

            <Skeleton width="100%" isLoaded={!isLoadingBillingData} mt={0}>
              {billingDataOptions.length > 0 ? (
                <Select
                  placeholder=""
                  size="lg"
                  isFullWidth="true"
                  fontSize="5xl"
                  height="auto"
                  color="black"
                  mt={0}
                  bg="white"
                  onChange={e => onBillingDataChange(e)}
                >
                  {billingDataOptions.map(billingData => (
                    <option
                      style={{ backgroundColor: 'white', color: 'black' }}
                      key={billingData.id}
                      value={JSON.stringify(billingData)}
                    >
                      {billingData.legalName}
                    </option>
                  ))}
                </Select>
              ) : (
                <>No tenes facturas para ver.</>
              )}
            </Skeleton>
            <HStack width="100%" mt="20px">
              <Box>
                <VStack fontSize="1xl" fontWeight={600} textAlign="start" width="300px">
                  <Text width="100%">Saldo total en $</Text>

                  {isLoading ? (
                    <Spinner size="xl" textAlign="start" />
                  ) : (
                    <Text fontSize="2xl" fontWeight={600} width="100%">
                      {totalBalance && `$  ${toCurrency(totalBalance)}`}
                    </Text>
                  )}
                </VStack>
              </Box>
              <Box flex={1} />
              <Box>
                <VStack fontSize="1xl" fontWeight={600} textAlign="end" width="300px">
                  <Text width="100%">CBU: {billingDataSelected?.cbu}</Text>
                  <Text width="100%">CUIT: {billingDataSelected?.cuit}</Text>
                </VStack>
              </Box>
            </HStack>
          </Stack>
          <Stack
            borderWidth="1px"
            borderRadius="lg"
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow="2xl"
            padding={4}
            w="100%"
          >
            <Tabs width="100%" variant="enclosed">
              <TabList>
                <Tab>Movimientos</Tab>
                <Tab>Pendientes de Cobro/Pago</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  {isLoading && <Spinner size="xl" />}
                  {!isLoading && (
                    <>
                      <Filter
                        updateFilters={updateFilters}
                        setDocumentNumberSearch={setDocumentNumberSearch}
                        searchCheckingBalanceData={searchCheckingBalanceData}
                      />
                      <CheckingAccountTable data={data} />
                    </>
                  )}
                </TabPanel>
                <TabPanel>
                  {isLoading && <Spinner size="xl" />}
                  {!isLoading && (
                    <>
                      <Filter
                        updateFilters={updateFiltersComposition}
                        setDocumentNumberSearch={setDocumentNumberCompositionSearch}
                        searchCheckingBalanceData={searchCompositionData}
                      />
                      <CheckingAccountTable data={compositionData} />
                    </>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
        </VStack>
      </Container>
    </>
  );
};

export default CheckingAccount;

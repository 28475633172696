/* eslint-disable camelcase */
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { pdfjs } from 'react-pdf';
import './index.css';
// eslint-disable-next-line import/named
import { ConfigProvider } from 'antd';
import en_US from 'antd/es/locale/en_US';
import es_ES from 'antd/es/locale/es_ES';
import br_BR from 'antd/es/locale/pt_BR';
import App from './App';
import config from './api/config';
import * as serviceWorker from './serviceWorker';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const tagManagerArgs = {
  gtmId: config.GTM_ID,
};

ReactGA.initialize(config.GA_UID);
ReactGA.pageview(window.location.pathname + window.location.search);

const chakraTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
  },
  styles: {
    global: props => ({
      body: {
        bg: mode('#1c2023', '#1c2023')(props),
      },
    }),
  },
});

const localeMap = {
  es: es_ES,
  en: en_US,
  br: br_BR,
  // agregar otros locale en caso de ser necesario
};

const getAntdLocale = () => {
  const browserLocale = navigator.language.split('-')[0]; // Get the language part only, e.g., 'en' from 'en-US'
  // eslint-disable-next-line camelcase
  return es_ES;
};

TagManager.initialize(tagManagerArgs);

const locale = getAntdLocale();

ReactDOM.render(
  <ChakraProvider theme={chakraTheme}>
    <ConfigProvider locale={locale}>
      <App />
    </ConfigProvider>
  </ChakraProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
